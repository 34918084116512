import BlogList from './BlogList';

const FAQ = (props) => {
	return (
		<details className='mb-2'>
			<summary className='font-semibold rounded-md py-2 px-4'>{props.title}</summary>
			<span className='ml-10'>{props.body}</span>
		</details>
	);
};

const AboutUs = () => {
	const faqs = [
		{ ekey: 1, title: 'Which Exchanges are supported?', body: 'Currently we support Coinbase Pro. Working on adding more.' },
		{ ekey: 2, title: 'Why do you use red color for buy and green for sell orders?', body: 'As an investment, we believe crypto is risky. Hence, when you are buying (putting your money in), we chose red color. Also, we wish and bless that everyone to sell for profit, hence green.' },
		{ ekey: 3, title: 'What are the notification options?', body: 'Currently we have Browser, e-mail, Telegram notifications. Working on adding more.' },
	];
	return (
		<>
			<div className='bg-white bg-opacity-30 rounded-md md:mr-5 md:w-3/4 overflow-y-auto min-h-full md:min-h-0 shadow-sm'>
				<div className='container p-5 mx-auto'>
					<h1 className='sm:text-3xl text-2xl font-medium title-font text-gray-900 mb-4'>About Us</h1>
					<p className='text-base leading-relaxed'>We are a small team who believe in Crypto Technology and also want to help fellow Cryptoers. This website/app is to help you with the notifications of limit orders so that you can re-invest the money right away.</p>
					<div className=''>
						<h1 className='sm:text-2xl text-xl mt-5 font-medium title-font text-gray-900 mb-4'>Frequently Asked Question</h1>
						<p className='text-base leading-relaxed'>The most common questions about how our business works and what can do for you.</p>
					</div>
					{faqs.map((faq) => {
						return <FAQ title={faq.title} body={faq.body} ekey={faq.ekey} key={faq.ekey} />;
					})}
				</div>
			</div>
			<div className='mt-5 md:mt-0 md:w-1/4 shadow-sm'>
				<BlogList />
			</div>
		</>
	);
};

export default AboutUs;
