import { currencyFormat, numberFormat } from '../helpers/commonFunctions';

const OrderItem = (props) => {
	return (
		<div className={'border-2 border-white border-opacity-70 rounded-md mb-3 flex flex-row ' + (props.order.side.toLowerCase() === 'buy' ? 'bg-red-300' : 'bg-green-300')}>
			<div className='w-5 md:w-14 md:my-auto text-center h-full flex-none text-white p-1'>
				{props.order.side == 'buy' ? (
					<span>
						B<br className='md:hidden' />U<br className='md:hidden' />Y<br className='md:hidden' />
					</span>
				) : (
					<span>
						S<br className='md:hidden' />E<br className='md:hidden' />L<br className='md:hidden' />L
					</span>
				)}
			</div>
			<div className='bg-white bg-opacity-80 rounded-l-md flex-grow flex flex-row items-center md:p-4'>
				<div className='flex-grow grid grid-cols-2 md:grid-cols-5 justify-center px-1'>
					<div className='text-md md:text-xl'>{props.order.product_id}</div>
					<div className='text-md md:text-xl'>{'Coins: ' + numberFormat(props.order.size)}</div>
					<div className='text-md md:text-xl'>{'Price: ' + currencyFormat(props.order.price)}</div>
					<div className='text-md md:text-xl'>{'Amount: ' + currencyFormat(parseFloat(props.order.price) * parseFloat(props.order.size))}</div>
					<div className='text-md md:text-xl'>{props.order.apiName}</div>
				</div>
			</div>
		</div>
	);
};

export default OrderItem;
