import React from 'react';
import { useCollection } from 'react-firebase-hooks/firestore';
import { useAuthState } from 'react-firebase-hooks/auth';
import { firestore, auth } from '../firebase';
import OrderItem from './OrderItem';
import BlogList from './BlogList';

const Orders = (props) => {
	const [user, userloading, usererror] = useAuthState(auth);
	const [value, loading, error] = useCollection(user && firestore.collection('/users/' + user.uid + '/orders').orderBy('created_at', 'desc'), {
		snapshotListenOptions: { includeMetadataChanges: true },
	});
	if (!user) {
		props.history.push('/');
	}
	if (userloading) {
		return <p>Loading...</p>;
	} else if (user) {
		if (loading) {
			return <p>Loading...</p>;
		} else if (error) {
			console.log('Orders error:', error);
			return (
				<p>
					Error occured. <br />
					Click{' '}
					<a className='font-semibold underline' href='/'>
						here
					</a>{' '}
					for home.
				</p>
			);
		} else {
			return (
				<>
					<div className='bg-white bg-opacity-30 rounded-md md:mr-5 md:w-3/4 overflow-y-auto min-h-full md:min-h-0 shadow-sm'>
						<div className='p-5 rounded-md h-full'>
							<h2 className='text-2xl mb-5'>Open orders</h2>
							{value && (
								<>
									{value.docs.map((doc) => {
										const order = { id: doc.id, ...doc.data() };
										if (order.status == 'open')
											return (
												<React.Fragment key={order.id}>
													<OrderItem order={order} />
												</React.Fragment>
											);
									})}
								</>
							)}
							<h2 className='text-2xl mb-5'>Recently completed orders</h2>
							{value && (
								<>
									{value.docs.map((doc) => {
										const order = { id: doc.id, ...doc.data() };
										if (order.status !== 'open' && order.status !== 'NotFound')
											return (
												<React.Fragment key={order.id}>
													<OrderItem order={order} />
												</React.Fragment>
											);
									})}
								</>
							)}
						</div>
					</div>
					<div className='mt-5 md:mt-0 md:w-1/4 shadow-sm'>
						<BlogList />
					</div>
				</>
			);
		}
	}
};

export default Orders;
