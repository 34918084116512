import { currencyFormat, numberFormat } from '../helpers/commonFunctions';

const ApiKeyItem = (props) => {
	return (
		<div className={'border-2 border-white border-opacity-70 rounded-md mb-3 flex flex-row ' + (props.apikey.error ? 'bg-red-300' : 'bg-green-300')}>
			<div className='bg-white bg-opacity-80 flex-grow flex flex-row items-center md:p-4'>
				<div className='flex-grow grid grid-cols-2 md:grid-cols-4 justify-center px-1'>
					<div className='text-md md:text-xl'>{props.apikey.name}</div>
					<div className='text-md md:text-xl'>{props.apikey.exchangeName}</div>
					{props.apikey.error && (
						<>
							<div className='text-md text-textcolor-error md:text-xl'>{props.apikey.error_message}</div>
						</>
					)}
				</div>
			</div>
			<div className='w-8 md:w-14 md:my-auto text-center text-red-500 h-full flex-none p-1'>
				<button
					onClick={(e) => {
						e.preventDefault();
						props.deleteAPIKey(props.apikey.id);
					}}
				>
					<svg className='w-6 h-6' fill='none' stroke='currentColor' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
						<path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16'></path>
					</svg>
				</button>
			</div>
		</div>
	);
};

export default ApiKeyItem;
