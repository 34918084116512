import { auth, firestore, projectStorage } from '../firebase';
import BlogList from './BlogList';
import { useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useDocumentData } from 'react-firebase-hooks/firestore';

const Profile = (props) => {
	const [user, loading, error] = useAuthState(auth);
	const [value, docLoading, docError] = useDocumentData(user && firestore.doc('users/' + user.uid), {
		snapshotListenOptions: { includeMetadataChanges: true },
	});
	const [updateObj, setUpdateObj] = useState({
		name: null,
		photoURL: null,
		quote: null,
		edit: false,
	});
	const [file, setFile] = useState(null);
	const [fileError, setError] = useState(null);
	const [progress, setProgress] = useState(0);
	const types = ['image/png', 'image/jpeg'];

	/* Google SignIn */
	async function updateDetails(e) {
		e.preventDefault();
		if (file) {
			const storageRef = projectStorage.ref('users/' + user.uid + '/' + file.name);

			await storageRef.put(file).on(
				'state_changed',
				(snap) => {
					let percentage = (snap.bytesTransferred / snap.totalBytes) * 100;
					setProgress(percentage);
				},
				(err) => {
					setError(err);
				},
				async () => {
					const url = await storageRef.getDownloadURL();
					const update = {
						displayName: updateObj.name,
						photoURL: url,
					};

					await auth.currentUser.updateProfile(update);
					setUpdateObj({ ...updateObj, edit: false });
				}
			);
		} else {
			const update = {
				displayName: updateObj.name,
				photoURL: user.photoURL,
			};
			await auth.currentUser.updateProfile(update);
			setUpdateObj({ ...updateObj, edit: false });
		}

		if (updateObj.quote && updateObj.quote !== '') {
			const userDocRef = firestore.doc(`users/${user.uid}`);
			try {
				await userDocRef.set(
					{
						quote: updateObj.quote,
					},
					{ merge: true }
				);
			} catch (error) {
				console.error('Error updating quote', error);
			}
		}
	}

	const changeHandler = (e) => {
		let selected = e.target.files[0];
		if (selected && types.includes(selected.type)) {
			setFile(selected);
			setError('');
		} else {
			setFile(null);
			setError('Please select an image file (png or jpeg)');
		}
	};
	if (!user) {
		props.history.push('/');
	}
	return (
		<>
			<div className='bg-white bg-opacity-30 rounded-md md:mr-5 md:w-3/4 overflow-y-auto min-h-full md:min-h-0 shadow-sm'>
				<div className='p-5'>
					<p className='text-xl mb-5'>Preferences</p>
					<div className={updateObj.edit ? 'hidden' : 'mx-auto space-y-4'}>
						<div>
							<b>Email:</b> {user.email}
						</div>
						<div>
							<b>Name:</b> {user.displayName ? user.displayName : ''}
						</div>
						<div className=''>
							<b>Photo:</b>
							{user.photoURL ? <img className='ml-10 w-1/2 md:w-1/4 inline-block' src={user.photoURL} alt='User photo' /> : <span className='ml-5'>No Photo</span>}
						</div>
						<div>
							<b>Quote:</b> {value && value.quote}
						</div>
						<button
							className='px-6 mt-5 mb-10 rounded bg-primary-700 hover:shadow-lg hover:bg-primary-500 py-3 text-white font-bold shadow'
							onClick={(e) => {
								e.preventDefault();
								setUpdateObj({ ...updateObj, name: user.displayName, photoURL: user.photoURL, quote: value.quote, edit: true });
							}}
						>
							Update
						</button>
					</div>
					<form className={!updateObj.edit ? 'hidden' : 'mx-auto'}>
						<div className='flex items-center bg-white bg-opacity-70 rounded-t-lg border-b border-white'>
							<label htmlFor='name' className='w-20 text-right mr-8 text-gray-700'>
								Name
							</label>
							<input type='text' id='name' name='name' placeholder='Full Name' className='flex-1 p-4 pl-0 bg-transparent placeholder-gray-500 outline-none text-gray-700' value={updateObj.name} onChange={(e) => setUpdateObj({ ...updateObj, name: e.target.value })} />
						</div>
						<div className='flex items-center bg-white bg-opacity-70 border-b border-white'>
							<label htmlFor='photo' className='w-20 text-right mr-8 text-gray-700'>
								Photo
							</label>
							<input type='file' id='photo' name='photo' onChange={changeHandler} />
							{fileError && <div className='text-textcolor-error'>{fileError}</div>}
							{file && <div>uploading... {progress} %</div>}
						</div>
						<div className='flex items-center bg-white bg-opacity-70 rounded-t-lg border-b border-white'>
							<label htmlFor='quote' className='w-20 text-right mr-8 text-gray-700'>
								Quote
							</label>
							<input type='quote' id='quote' name='name' placeholder='Full Name' className='flex-1 p-4 pl-0 bg-transparent placeholder-gray-500 outline-none text-gray-700' value={updateObj.quote} onChange={(e) => setUpdateObj({ ...updateObj, quote: e.target.value })} />
						</div>

						<div className='text-center mb-5 space-x-2'>
							<button type='submit' className='px-6 mt-5 mb-10 rounded bg-primary-700 hover:shadow-lg hover:bg-primary-500 py-3 text-white font-bold shadow' onClick={updateDetails}>
								Update Info
							</button>
							<button
								type='submit'
								className='px-6 mt-5 mb-10 rounded bg-primary-500 hover:shadow-lg hover:bg-primary-300 py-3 text-white font-bold shadow'
								onClick={(e) => {
									e.preventDefault();
									setUpdateObj({ ...updateObj, edit: false });
								}}
							>
								Cancel
							</button>
						</div>
					</form>
				</div>
			</div>
			<div className='mt-5 md:mt-0 md:w-1/4 shadow-sm'>
				<BlogList />
			</div>
		</>
	);
};

export default Profile;
