const BlogListItem = (props) => {
	return (
		<div className='border-2 border-white border-opacity-70 rounded-md p-5 pb-12 mb-4'>
			<h3 className='text-lg'>{props.post.t}</h3>
			<p className='line-clamp-3 text-md'>{props.post.intro}</p>
			<a className='bg-primary-300 hover:bg-primary-400 text-textcolor-800 font-bold py-2 px-4 rounded inline-flex items-center float-right' href={'/blog/' + props.post.id}>
				<svg className='fill-current w-4 h-4 mr-2' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
					<path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253' />
				</svg>
				<span>Read</span>
			</a>
		</div>
	);
};

export default BlogListItem;
