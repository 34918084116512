import { firestore } from '../firebase';
import { useDocumentData } from 'react-firebase-hooks/firestore';

const NotificationsCount = (props) => {
	const [value, docLoading, docError] = useDocumentData(firestore.doc('users/' + props.uid), {
		snapshotListenOptions: { includeMetadataChanges: true },
	});
	if (docLoading || !value || docError) {
		return <></>;
	}
	return <span>{value && value.notifications_unread > 0 ? value.notifications_unread : ''}</span>;
};

export default NotificationsCount;
