import Markup from 'interweave';
import { withRouter } from 'react-router';
import { useDocumentOnce } from 'react-firebase-hooks/firestore';
import { firestore } from '../firebase';
import { compress, decompress, formatBlogTitle } from '../helpers/commonFunctions';
import { useState } from 'react';

const BlogCreatePost = (props) => {
	const { postid } = props.match.params;
	console.log('Post ID: ', postid);
	const [blogObj, setBlogObj] = useState({
		title: '',
		intro: '',
		tags: '',
		content: '',
		editing: false,
	});
	const [value, docLoading, docError] = useDocumentOnce(postid && firestore.doc('data/blog/content/' + postid), {
		snapshotListenOptions: { includeMetadataChanges: true },
	});

	const changeHandler = (e) => {
		setBlogObj({ ...blogObj, [e.target.name]: e.target.value });
	};

	const editPost = (e) => {
		e.preventDefault();
		if (postid) {
			const doc = value.data();
			setBlogObj({ ...blogObj, title: doc.t, content: decompress(doc.cHTML), tags: doc.cat.join(','), intro: doc.intro, editing: true });
		} else {
			setBlogObj({ ...blogObj, editing: true });
		}
	};

	const addUpdateBlogPost = async (e) => {
		e.preventDefault();
		// If blogID exists, then update content
		window.grecaptcha.ready(function () {
			window.grecaptcha.execute('6Lfwyz0aAAAAAFFCRdTQKX9AS-RhOqJKlUNj_i5x', { action: 'submit' }).then(async function (token) {
				if (postid) {
					await firestore
						.collection('data/blog/meta')
						.doc(postid)
						.set({ t: blogObj.title, cat: blogObj.tags.split(','), intro: blogObj.intro, updated: new Date() }, { merge: true })
						.then((res) => {
							alert('Post meta updated!.');
						})
						.catch((err) => {
							alert('Error udpating blog post:', err);
						});
					await firestore
						.collection('data/blog/content')
						.doc(postid)
						.set({ t: blogObj.title, cHTML: compress(blogObj.content), cat: blogObj.tags.split(','), intro: blogObj.intro, updated: new Date() }, { merge: true })
						.then((res) => {
							alert('Post content updated!.');
						})
						.catch((err) => {
							alert('Error udpating content:', err);
						});
				}
				// If no blogID, then create new one and re-direct with the newly created blogid
				else {
					await firestore
						.collection('data/blog/meta')
						.doc(formatBlogTitle(blogObj.title))
						.set({ t: blogObj.title, cat: blogObj.tags.split(','), intro: blogObj.intro, created: new Date() }, { merge: true })
						.then((res) => {
							alert('Post meta Added!.');
						})
						.catch((err) => {
							alert('Error Adding blog post:', err);
						});

					await firestore
						.collection('data/blog/content')
						.doc(formatBlogTitle(blogObj.title))
						.set({ t: blogObj.title, cHTML: compress(blogObj.content), cat: blogObj.tags.split(','), intro: blogObj.intro, created: new Date() }, { merge: true })
						.then((res) => {
							alert('Post Added!.');
						})
						.catch((err) => {
							alert('Error Adding content:', err);
						});
				}
				// setBlogObj({ ...blogObj, title: '', intro: '', tags: '', content: '', editing: false });
				props.history.push('/createBlogPost/' + formatBlogTitle(blogObj.title));
			});
		});
	};

	if (docLoading) {
		return <p>Loading...</p>;
	} else if (docError || (value && value.data() == undefined)) {
		return (
			<p>
				Some error occured. Click{' '}
				<a className='font-semibold underline' href='/'>
					here
				</a>{' '}
				for home.
			</p>
		);
	} else {
		const doc = value ? value.data() : null;
		return (
			<div className='bg-white bg-opacity-30 rounded-md md:mr-5 overflow-y-auto min-h-full md:min-h-0 shadow-sm p-5 w-full'>
				<div className={blogObj.editing && postid ? 'hidden' : ''}>
					<h3 className='text-xl mb-5'>
						<span className='font-bold'>Blog Formatted Title:</span> {formatBlogTitle(doc ? doc.t : '')}
					</h3>
					<div className='text-2xl mb-5 w-full'>
						{doc && doc.t}
						<button className='float-right font-semibold hover:underline' onClick={editPost}>
							Edit
						</button>
					</div>
					<div className='my-5'>
						<span className='block font-bold'>Intro</span>
						{doc && doc.intro}
					</div>
					<div>
						<span className='block font-bold'>Content</span>
						<Markup content={value && decompress(doc.cHTML)} />
					</div>
				</div>
				<div className={!blogObj.editing && postid !== undefined ? 'hidden' : ''}>
					<p className='m-5 border-sm rounded-sm'>
						&lt;h3 class='text-xl mt-5'&gt; <br />
						&lt;h4 class='text-md font-semibold mt-3'&gt; <br />
						&lt;img class="inline w-1/2 float-right m-5" src='' alt=''&gt; <br />
						&lt;img class="inline w-1/4 float-right m-5" src='' alt=''&gt; <br />
						&lt;p class='mt-3'&gt; <br />
						&lt;p class='mt-3 font-bold'&gt; <br />
						&lt;ul class='list-disc'&gt; <br />
						&lt;ul class='list-decimal'&gt;
					</p>
					<form className='mx-auto mb-5'>
						<div className='flex items-center bg-white bg-opacity-70 rounded-t-lg border-b border-white'>
							<label htmlFor='title' className='w-28 text-right mr-8 text-gray-700'>
								Title
							</label>
							<input type='text' id='title' name='title' placeholder='blog title' className='flex-1 p-4 pl-0 bg-transparent placeholder-gray-500 outline-none text-gray-700' value={blogObj.title} onChange={changeHandler} />
						</div>
						<div className='flex items-center bg-white bg-opacity-70 rounded-t-lg border-b border-white'>
							<label htmlFor='intro' className='w-28 text-right mr-8 text-gray-700'>
								Intro
							</label>
							<input type='text' id='intro' name='intro' placeholder='blog title' className='flex-1 p-4 pl-0 bg-transparent placeholder-gray-500 outline-none text-gray-700' value={blogObj.intro} onChange={changeHandler} />
						</div>
						<div className='flex items-center bg-white bg-opacity-70 rounded-lg border-b border-white'>
							<label htmlFor='tags' className='w-28 text-right mr-8 text-gray-700'>
								Tags
							</label>
							<input type='text' id='tags' name='tags' placeholder='blog content' className='flex-1 p-4 pl-0 bg-transparent placeholder-gray-500 outline-none text-gray-700' value={blogObj.tags} onChange={changeHandler} />
						</div>
						<div className='flex items-center bg-white bg-opacity-70 rounded-b-lg border-b border-white'>
							<label htmlFor='string' className='w-28 text-right mr-8 text-gray-700'>
								Content
							</label>
							<textarea type='text' id='content' name='content' placeholder='blog content' className='flex-1 p-4 pl-0 bg-transparent placeholder-gray-500 outline-none text-gray-700' rows='15' value={blogObj.content} onChange={changeHandler} />
						</div>

						<div className='w-full text-center'>
							<button className='px-6 mt-5 rounded bg-primary-700 hover:shadow-lg hover:bg-primary-500 py-3 text-white font-bold shadow' onClick={addUpdateBlogPost}>
								Add/Update
							</button>
						</div>
					</form>
					<h3 className='text-xl'>Blog Formatted Title: {formatBlogTitle(blogObj.title)}</h3>
					<div className='text-2xl'>{blogObj.title}</div>
					<div>
						<Markup content={blogObj.content} />
					</div>
				</div>
			</div>
		);
	}
};

export default BlogCreatePost;
