import { useAuthState } from 'react-firebase-hooks/auth';
import { useDocumentOnce } from 'react-firebase-hooks/firestore';
import { auth, firestore } from '../firebase';

const HomeLoggedInInfo = () => {
	const [user, loading, error] = useAuthState(auth);
	const [value, docLoading, docError] = useDocumentOnce(user && firestore.doc('users/' + user.uid), {
		snapshotListenOptions: { includeMetadataChanges: true },
	});
	const docData = value ? value.data() : null;
	if (!value || docError) {
		return <></>;
	}
	if (value) {
		return (
			<div>
				<p>
					Hello <span className='font-bold'>{user && user.displayName}</span>
				</p>
				{docData.quote && (
					<div className='my-5'>
						<div className='w-full mb-10'>
							<div className='text-2xl text-textcolor-600 text-left leading-tight h-3'>“</div>
							<p className='text-lg text-textcolor-800 text-center px-5'>{docData.quote}</p>
							<div className='text-2xl text-textcolor-600 text-right leading-tight h-3 -mt-3'>”</div>
						</div>
					</div>
				)}
				<div className='flex items-center space-x-5 md:mx-20 h-20 my-3'>
					<div className='flex-1 border border-textcolor-700 rounded-md h-full  text-center my-auto p-3'>
						Notifications
						<a href='/notifications' className='block text-2xl hover:underline'>
							{docData ? docData.notifications_unread : 0}
						</a>
					</div>
					<div className='flex-1 border border-textcolor-700 rounded-md h-full  text-center my-auto p-3'>
						Open Orders
						<a href='/orders' className='block text-2xl hover:underline'>
							{docData ? docData.open_orders : 0}
						</a>
					</div>
				</div>
			</div>
		);
	}
};

export default HomeLoggedInInfo;
