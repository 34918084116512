import React, { useState } from 'react';
import { useCollection } from 'react-firebase-hooks/firestore';
import { useAuthState } from 'react-firebase-hooks/auth';
import { firestore, auth } from '../firebase';
import ApiKeyItem from './ApiKeyItem';
import BlogList from './BlogList';

const ApiKeys = (props) => {
	// const [addNew, setAddNew] = useState(false);
	const [apiObj, setApiObj] = useState({
		secretKey: '',
		apiName: '',
		apiKey: '',
		passPhrase: '',
		exchangeName: '',
		addNew: false,
	});

	const [user, userloading, usererror] = useAuthState(auth);
	const [value, loading, error] = useCollection(user && firestore.collection('/users/' + user.uid + '/apikeys'), {
		snapshotListenOptions: { includeMetadataChanges: true },
	});

	const changeHandler = (e) => {
		setApiObj({ ...apiObj, [e.target.name]: e.target.value });
	};

	const addNewAPIKey = async (e) => {
		e.preventDefault();

		window.grecaptcha.ready(function () {
			window.grecaptcha.execute('6Lfwyz0aAAAAAFFCRdTQKX9AS-RhOqJKlUNj_i5x', { action: 'submit' }).then(async function (token) {
				await firestore.collection(`users/${user.uid}/apikeys`).add({
					apiKey: apiObj.apiKey,
					exchangeName: apiObj.exchangeName,
					name: apiObj.apiName,
					secretKey: apiObj.secretKey,
					passPhrase: apiObj.passPhrase,
					error: false,
					createdAt: Math.round(new Date().getTime() / 1000),
				});
				// setAddNew(false);
				setApiObj({
					SecretKey: '',
					NewName: '',
					APIKey: '',
					PasPhrase: '',
					exchangeName: '',
					addNew: false,
				});
			});
		});
	};

	const deleteAPIKey = async (apiKeyID) => {
		if (user) {
			/* Delete the API Key and It's orders using the batch operation. */
			const batch = firestore.batch();
			const apikeyDoc = firestore.doc(`users/${user.uid}/apikeys/${apiKeyID}`);
			batch.delete(apikeyDoc);
			const orders = await firestore.collection(`users/${user.uid}/orders`).where('apiKey', '==', apiKeyID).get();
			orders.forEach((doc) => {
				batch.delete(doc.ref);
			});
			await batch.commit();
		}
	};

	if (!user) {
		props.history.push('/');
	}

	if (userloading) {
		return <p>Loading...</p>;
	} else if (user) {
		if (loading) {
			return <p>Loading...</p>;
		} else if (error) {
			console.log('API Keys error:', error);
			return (
				<div>
					Error occured. <br />
					Click{' '}
					<a className='font-semibold underline' href='/'>
						here
					</a>{' '}
					for home.
				</div>
			);
		} else {
			return (
				<>
					<div className='bg-white bg-opacity-30 rounded-md md:mr-5 md:w-3/4 overflow-y-auto min-h-full md:min-h-0 shadow-sm'>
						<div className='p-5 rounded-md h-full'>
							<h2 className='text-2xl mb-5'>
								API Keys
								<button className='float-right' onClick={() => setApiObj({ ...apiObj, addNew: !apiObj.addNew })}>
									<svg className={'w-6 h-6 ' + (apiObj.addNew && 'hidden')} fill='none' stroke='currentColor' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
										<path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z'></path>
									</svg>
									<svg className={'w-6 h-6 ' + (!apiObj.addNew && 'hidden')} fill='none' stroke='currentColor' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
										<path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M6 18L18 6M6 6l12 12'></path>
									</svg>
								</button>
							</h2>
							<div className={'bg-primary-500 bg-opacity-20 rounded-md m-4 p-5 ' + (!apiObj.addNew && 'hidden')}>
								<h4 className='text-xl mb-3'>Add new api key</h4>
								<form className='mx-auto'>
									<div className='flex items-center bg-white bg-opacity-70 rounded-t-lg border-b border-white'>
										<label htmlFor='name' className='w-28 text-right mr-8 text-gray-700'>
											Name
										</label>
										<input type='text' id='apiName' name='apiName' placeholder='identify your api key' className='flex-1 p-4 pl-0 bg-transparent placeholder-gray-500 outline-none text-gray-700' value={apiObj.apiName} onChange={changeHandler} />
									</div>
									<div className='flex items-center bg-white bg-opacity-70 border-b border-white'>
										<label htmlFor='api' className='w-28 text-right mr-8 text-gray-700'>
											API
										</label>
										<input type='text' id='exchangeName' name='exchangeName' placeholder='Exchange name: coinbase-pro, bittrex' className='flex-1 p-4 pl-0 bg-transparent placeholder-gray-500 outline-none text-gray-700' value={apiObj.exchangeName} onChange={changeHandler} />
									</div>
									<div className='flex items-center bg-white bg-opacity-70 border-b border-white'>
										<label htmlFor='exchangename' className='w-28 text-right mr-8 text-gray-700'>
											API Key
										</label>
										<input type='text' id='apiKey' name='apiKey' placeholder='API Key' className='flex-1 p-4 pl-0 bg-transparent placeholder-gray-500 outline-none text-gray-700' value={apiObj.apiKey} onChange={changeHandler} />
									</div>
									<div className='flex items-center bg-white bg-opacity-70 border-b border-white'>
										<label htmlFor='secretkey' className='w-28 text-right mr-8 text-gray-700'>
											Secret Key
										</label>
										<input type='text' id='secretKey' name='secretKey' placeholder='Secret Key' className='flex-1 p-4 pl-0 bg-transparent placeholder-gray-500 outline-none text-gray-700' value={apiObj.secretKey} onChange={changeHandler} />
									</div>
									{apiObj.exchangeName == 'coinbase-pro' && (
										<div className='flex items-center bg-white bg-opacity-70 rounded-b-lg border-b border-white'>
											<label htmlFor='passphrase' className='w-28 text-right mr-8 text-gray-700'>
												Passphrase
											</label>
											<input type='text' id='passPhrase' name='passPhrase' placeholder='passphrase' className='flex-1 p-4 pl-0 bg-transparent placeholder-gray-500 outline-none text-gray-700' value={apiObj.passPhrase} onChange={changeHandler} />
										</div>
									)}
									<div className='w-full text-center'>
										<button className='px-6 mt-5 rounded bg-primary-700 hover:shadow-lg hover:bg-primary-500 py-3 text-white font-bold shadow' onClick={addNewAPIKey}>
											Add API Key
										</button>
										<button
											className='ml-5 px-6 mt-5 rounded bg-yellow-300 text-textcolor-800 hover:shadow-lg hover:bg-primary-700 hover:text-textcolor-300 py-3  font-bold shadow'
											onClick={() => {
												setApiObj({ apiName: '', apiKey: '', secretKey: '', passPhrase: '', addNew: false });
												// setAddNew(false);
											}}
										>
											Cancel
										</button>
									</div>
								</form>
							</div>
							{value && (
								<>
									{value.docs.map((doc) => {
										const apikey = { id: doc.id, ...doc.data() };
										return (
											<React.Fragment key={apikey.id}>
												<ApiKeyItem apikey={apikey} deleteAPIKey={deleteAPIKey} />
											</React.Fragment>
										);
									})}
								</>
							)}
						</div>
					</div>
					<div className='mt-5 md:mt-0 md:w-1/4 shadow-sm'>
						<BlogList />
					</div>
				</>
			);
		}
	}
};

export default ApiKeys;
