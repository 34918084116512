import { useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { auth, firestore } from '../firebase';
import BlogList from './BlogList';

const NotificationPreferences = () => {
	const [prefObj, setPrefObj] = useState({
		prefSMSCheck: false,
		prefSMS: '',
		prefEmailCheck: false,
		prefEmail: '',
		prefTelegramCheck: false,
		prefTelegramChatID: '',
		prefTelegramToken: '',
		showSuccessAlert: false,
		showFailureAlert: false,
		prefPhoneVerified: false,
		editMode: false,
		error: '',
	});

	const [user] = useAuthState(auth);
	const [value, docLoading, docError] = useDocumentData(user && firestore.doc('users/' + user.uid), {
		snapshotListenOptions: { includeMetadataChanges: true },
	});

	const changeHandler = (e) => {
		setPrefObj({ ...prefObj, [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value });
	};

	const updatePref = async (e) => {
		e.preventDefault();
		var telegram = {
			chatid: prefObj.prefTelegramChatID,
			token: prefObj.prefTelegramToken,
		};
		var sms = {
			phone: prefObj.prefSMS,
		};
		var prefObject = {};

		if (prefObj.prefSMSCheck) {
			prefObject['sms'] = sms;
		}
		if (prefObj.prefTelegramCheck) {
			prefObject['telegram'] = telegram;
		}
		if (prefObj.prefEmailCheck) {
			prefObject['email'] = prefObj.prefEmail;
		}
		await firestore
			.doc(`users/${user.uid}`)
			.set(
				{
					notifications: prefObject,
				},
				{ merge: true }
			)
			.then((res) => {
				//this.setState({ showSuccessAlert: true });
				clearPref();
			})
			.catch((err) => {
				//this.setState({ showFailureAlert: true });
				setPrefObj({ ...prefObj, error: 'Error occured: please try in few minutes.' });
			});
	};
	const setEditMode = () => {
		setPrefObj({ ...prefObj, prefSMSCheck: value.notifications.sms ? true : false, prefSMS: value.notifications.sms ? value.notifications.sms.phone : '', prefEmailCheck: value.notifications.email ? true : false, prefEmail: value.notifications.email, prefTelegramCheck: value.notifications.telegram, prefTelegramChatID: value.notifications.telegram ? value.notifications.telegram.chatid : '', prefTelegramToken: value.notifications.telegram ? value.notifications.telegram.token : '', editMode: true, error: '' });
	};

	const clearPref = () => {
		setPrefObj({ ...prefObj, prefSMSCheck: false, prefSMS: '', prefEmailCheck: false, prefEmail: '', prefTelegramCheck: false, prefTelegramChatID: '', prefTelegramToken: '', showSuccessAlert: false, showFailureAlert: false, prefPhoneVerified: false, editMode: false, error: '' });
	};
	if (docLoading) {
		<div className='p-5 rounded-md h-full'>Loading...</div>;
	}

	return (
		<>
			<div className='bg-white bg-opacity-30 rounded-md md:mr-5 md:w-3/4 overflow-y-auto min-h-full md:min-h-0 shadow-sm'>
				<div className='p-5 rounded-md h-full'>
					<h2 className='text-2xl mb-5 w-full'>
						Notification Preferences
						<span className='float-right' onClick={setEditMode}>
							<button className='hover:underline hover:shadow-md'>Edit</button>
						</span>
					</h2>
					<div className={prefObj.editMode ? 'hidden' : ''}>
						<div className='text-xl'>
							E-mail: <span className='font-semibold'> {value && value.notifications && value.notifications.email ? 'Enabled' : 'Disabled'} </span>
						</div>
						<div className='text-md ml-5'>E-mail Address: {value && value.notifications && value.notifications.email} </div>
						<div className='text-xl mt-5'>
							SMS: <span className='font-semibold'> {value && value.notifications && value.notifications.sms ? 'Enabled' : 'Disabled'} </span>
						</div>
						<div className='text-md ml-5'>Mobile No.: {value && value.notifications.sms && value.notifications.sms.phone} </div>
						<div className='text-xl mt-5'>
							Telegram: <span className='font-semibold'> {value && value.notifications && value.notifications.telegram ? 'Enabled' : 'Disabled'} </span>{' '}
						</div>
						<div className='text-md ml-5'>
							<div>Token: {value && value.notifications.telegram && value.notifications.telegram.token} </div>
							<div>Chat ID: {value && value.notifications.telegram && value.notifications.telegram.chatid} </div>
						</div>
					</div>
					<div className={!prefObj.editMode ? 'hidden' : ''}>
						<input type='checkbox' className='checked:bg-primary-500 checked:border-transparent' name='prefEmailCheck' checked={prefObj.prefEmailCheck} onChange={changeHandler}></input> E-mail
						<div className='flex items-center bg-white bg-opacity-70 rounded-lg border-b border-white'>
							<label htmlFor='prefEmail' className='w-28 text-right mr-8 text-gray-700'>
								E-mail
							</label>
							<input type='text' id='prefEmail' name='prefEmail' placeholder='e-mail address' className='flex-1 p-4 pl-0 bg-transparent placeholder-gray-500 outline-none text-gray-700' value={prefObj.prefEmail} onChange={changeHandler} />
						</div>
						<input type='checkbox' className='checked:bg-primary-500 checked:border-transparent mt-5' name='prefSMSCheck' checked={prefObj.prefSMSCheck} onChange={changeHandler}></input> SMS (US Only)
						<div>NOTE: You will receive SMS from number: +1 (570) 972-2167.</div>
						<div className='flex items-center bg-white bg-opacity-70 rounded-lg border-b border-white'>
							<label htmlFor='prefSMS' className='w-28 text-right mr-8 text-gray-700'>
								Mobile
							</label>
							<input type='text' id='prefSMS' name='prefSMS' placeholder='Mobile number' className='flex-1 p-4 pl-0 bg-transparent placeholder-gray-500 outline-none text-gray-700' value={prefObj.prefSMS} onChange={changeHandler} />
						</div>
						<input type='checkbox' className='checked:bg-primary-500 checked:border-transparent mt-5' name='prefTelegramCheck' checked={prefObj.prefTelegramCheck} onChange={changeHandler}></input> Telegram
						<div className='flex items-center bg-white bg-opacity-70 rounded-t-lg border-b border-white'>
							<label htmlFor='prefTelegramToken' className='w-28 text-right mr-8 text-gray-700'>
								Token
							</label>
							<input type='text' id='prefTelegramToken' name='prefTelegramToken' placeholder='Token' className='flex-1 p-4 pl-0 bg-transparent placeholder-gray-500 outline-none text-gray-700' value={prefObj.prefTelegramToken} onChange={changeHandler} />
						</div>
						<div className='flex items-center bg-white bg-opacity-70 rounded-b-lg border-b border-white'>
							<label htmlFor='prefTelegramToken' className='w-28 text-right mr-8 text-gray-700'>
								ChatID
							</label>
							<input type='text' id='prefTelegramChatID' name='prefTelegramChatID' placeholder='ChatID' className='flex-1 p-4 pl-0 bg-transparent placeholder-gray-500 outline-none text-gray-700' value={prefObj.prefTelegramChatID} onChange={changeHandler} />
						</div>
						<div className='w-full text-center'>
							<button className='px-6 mt-5 rounded bg-primary-700 hover:shadow-lg hover:underline py-3 text-white font-bold shadow' onClick={updatePref}>
								Update
							</button>
							<button className='ml-5 px-6 mt-5 rounded bg-yellow-300 text-textcolor-800 hover:shadow-lg hover:underline py-3  font-bold shadow' onClick={clearPref}>
								Cancel
							</button>
						</div>
						<div className={prefObj.error === '' ? ' hidden' : 'w-full text-center text-textcolor-error'}>{prefObj.error}</div>
					</div>
				</div>
			</div>
			<div className='mt-5 md:mt-0 md:w-1/4 shadow-sm'>
				<BlogList />
			</div>
		</>
	);
};

export default NotificationPreferences;
