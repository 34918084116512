import React from 'react';
import Moment from 'react-moment';

export const currencyFormat = (num) => {
	return (
		'$' +
		parseFloat(num)
			.toFixed(2)
			.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
	);
};

export const numberFormat = (num) => {
	return (
		'' +
		parseFloat(num)
			.toFixed(0)
			.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
	);
};

export const dateFormat = (ddtt) => {
	return <Moment format='MMM-DD-YYYY'>{ddtt}</Moment>;
};

/* compress and decompress from https://stackoverflow.com/questions/294297/javascript-implementation-of-gzip  */
export const compress = (data) => {
	if (data == null) {
		return null;
	} else {
		// const cryptr = new Cryptr(encString);
		// return cryptr.compress(data);
		var dict = {};
		var data = (data + '').split('');
		var out = [];
		var currChar;
		var phrase = data[0];
		var code = 256;
		for (var i = 1; i < data.length; i++) {
			currChar = data[i];
			if (dict[phrase + currChar] != null) {
				phrase += currChar;
			} else {
				out.push(phrase.length > 1 ? dict[phrase] : phrase.charCodeAt(0));
				dict[phrase + currChar] = code;
				code++;
				phrase = currChar;
			}
		}
		out.push(phrase.length > 1 ? dict[phrase] : phrase.charCodeAt(0));
		for (var i = 0; i < out.length; i++) {
			out[i] = String.fromCharCode(out[i]);
		}
		return out.join('');
	}
};

export const decompress = (data) => {
	if (data == null) {
		return null;
	} else {
		var dict = {};
		var data = (data + '').split('');
		var currChar = data[0];
		var oldPhrase = currChar;
		var out = [currChar];
		var code = 256;
		var phrase;
		for (var i = 1; i < data.length; i++) {
			var currCode = data[i].charCodeAt(0);
			if (currCode < 256) {
				phrase = data[i];
			} else {
				phrase = dict[currCode] ? dict[currCode] : oldPhrase + currChar;
			}
			out.push(phrase);
			currChar = phrase.charAt(0);
			dict[code] = oldPhrase + currChar;
			code++;
			oldPhrase = phrase;
		}
		return out.join('');
	}
};

export const formatBlogTitle = (title) => {
	return title.toLowerCase().trim().replace(/ /g, '-').replace(/,/g, '-').replace(/[?=]/g, '-').replace(/[&=]/g, '-');
};
