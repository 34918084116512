const Footer = () => {
	return (
		<footer className='py-2 border-t-2 border-primary-700 bg-primary-400 text-center w-full'>
			<div className='flex flex-col md:flex-row items-center w-full space-x-3 justify-center'>
				<div>www.mycryptoorders.com &copy; 2021 Sai Lakshmi LLC</div>
				<div>
					<a className='transform hover:scale-110 hover:underline' href='/about'>
						About Us and FAQs
					</a>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
