import React, { useState } from 'react';
import { useCollectionOnce } from 'react-firebase-hooks/firestore';
import { firestore } from '../firebase';
import BlogListItem from './BlogListItems';

const BlogList = () => {
	const [value, loading, error] = useCollectionOnce(firestore.collection('/data/blog/meta').orderBy('created', 'desc'), {
		snapshotListenOptions: { includeMetadataChanges: true },
	});
	if (loading) {
		return <p>Loading...</p>;
	}

	return (
		<>
			{/* {error && <strong>Error: {JSON.stringify(error)}</strong>} */}
			<div className='bg-white bg-opacity-30 p-5 rounded-md overflow-auto'>
				<h2 className='text-xl mb-5'>Recent Blog Posts</h2>
				{/* Currently we can't support search functionality due to Firestore limitations. */}

				{value && (
					<>
						{value.docs.map((doc) => {
							const post = { id: doc.id, ...doc.data() };
							return (
								<React.Fragment key={doc.id}>
									<BlogListItem post={post} />
								</React.Fragment>
							);
						})}
					</>
				)}
			</div>
		</>
	);
};

export default BlogList;
