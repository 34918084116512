import { auth, firestore } from '../firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useDocument } from 'react-firebase-hooks/firestore';
import NotificationsCount from './NotificationsCount';

const Header = () => {
	const [user] = useAuthState(auth);
	const [value, docLoading, docError] = useDocument(user && firestore.doc('users/' + user.uid), {
		snapshotListenOptions: { includeMetadataChanges: true },
	});
	const docData = value ? value.data() : null;
	return (
		<header className='md:px-16 px-6 bg-primary-400 flex flex-wrap items-center md:py-0 py-2 border-b-2 border-primary-900 border-opacity-25'>
			<div className='flex-1 flex justify-between items-center'>
				<a href='/'>
					<svg width='32' height='36' version='1.0' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 770.000000 770.000000' stroke='currentColor' preserveAspectRatio='xMidYMid meet'>
						<g transform='translate(0.000000,770.000000) scale(0.100000,-0.100000)' fill='#000000' stroke='none'>
							<path
								strokeLinecap='round'
								strokeLinejoin='round'
								strokeWidth='2'
								d='M3640 7302 c-146 -38 -277 -157 -326 -294 -38 -110 -36 -270 6 -365
        11 -25 19 -47 17 -49 -1 -2 -59 -15 -127 -29 -296 -62 -618 -193 -885 -358
        -321 -199 -653 -534 -857 -863 -215 -348 -361 -781 -388 -1148 -5 -71 -13
        -641 -16 -1266 l-7 -1136 -74 -12 c-186 -29 -351 -170 -414 -353 -30 -89 -30
        -248 0 -334 47 -132 147 -242 280 -308 l84 -42 271 -7 c149 -3 636 -7 1083 -7
        l811 -1 7 -31 c12 -54 62 -166 96 -216 47 -68 149 -166 216 -206 32 -20 94
        -48 138 -63 71 -25 94 -28 210 -29 117 0 139 3 219 29 217 71 391 256 446 472
        l10 41 1058 6 c728 4 1071 9 1102 17 268 67 443 348 386 620 -21 100 -56 169
        -124 242 -93 100 -205 160 -327 174 l-52 6 -7 1157 c-3 636 -11 1209 -16 1274
        -24 290 -125 624 -282 929 -366 714 -1036 1232 -1822 1407 -77 17 -141 33
        -143 35 -2 2 5 39 16 82 42 168 37 247 -24 371 -103 210 -336 315 -565 255z
        m276 -185 c141 -72 208 -244 153 -391 -39 -105 -20 -99 -300 -99 l-244 0 -27
        39 c-97 140 -38 366 117 446 64 33 85 38 170 34 63 -2 91 -8 131 -29z m124
        -678 c469 -45 957 -247 1330 -551 l83 -68 -52 0 c-39 0 -53 -4 -58 -16 -10
        -27 25 -44 88 -44 l58 0 3 -67 c3 -60 5 -68 23 -68 17 0 21 8 25 54 l5 54 80
        -84 c79 -82 215 -250 215 -264 0 -4 -18 9 -40 28 -21 20 -58 46 -81 57 -88 46
        -126 52 -357 58 l-223 7 -18 50 c-46 134 -167 252 -311 303 -38 13 -120 16
        -529 19 -526 5 -578 1 -678 -46 -75 -36 -174 -127 -216 -200 -107 -183 -73
        -427 81 -578 65 -64 162 -119 232 -131 42 -7 45 -10 48 -41 4 -43 -40 -195
        -79 -272 -48 -97 -24 -112 91 -58 128 60 223 157 292 296 30 61 32 63 68 63
        36 0 38 -1 58 -58 53 -145 174 -259 324 -307 44 -14 111 -18 388 -23 l335 -5
        34 -69 c60 -121 162 -221 283 -278 64 -30 102 -37 112 -21 3 5 -10 42 -29 83
        -40 88 -75 208 -75 260 0 33 3 38 28 44 118 26 217 85 289 173 89 108 124 232
        104 365 -6 40 -14 81 -17 91 -5 11 -4 16 3 12 16 -10 148 -281 187 -385 65
        -171 109 -339 143 -547 15 -88 17 -244 20 -1287 l4 -1188 -2541 0 -2540 0 0
        1128 c0 1202 2 1250 51 1492 134 668 534 1255 1109 1630 116 75 379 205 515
        253 369 132 725 175 1135 136z m1574 -984 c113 -34 219 -126 270 -234 29 -62
        31 -73 31 -181 0 -110 -2 -119 -32 -180 -61 -125 -182 -215 -323 -242 -63 -13
        -65 -14 -68 -46 -5 -47 23 -181 52 -253 14 -35 23 -65 21 -67 -10 -10 -105 56
        -160 112 -41 42 -74 89 -105 151 l-46 90 -354 5 c-292 4 -362 8 -397 21 -234
        90 -347 318 -274 556 33 110 159 228 285 268 65 20 1033 20 1100 0z m-1617
        -47 c31 -29 30 -71 -3 -97 -34 -27 -62 -27 -91 1 -31 29 -30 71 3 97 34 27 62
        27 91 -1z m284 3 l23 -19 -38 -43 c-21 -24 -42 -47 -45 -52 -4 -4 -17 2 -29
        13 -29 27 -29 73 0 100 27 25 59 26 89 1z m2341 -3823 c313 -146 253 -615 -86
        -677 -84 -16 -5448 -15 -5531 0 -115 21 -205 90 -257 197 -29 59 -33 76 -33
        147 0 94 14 144 56 208 39 59 95 103 167 133 l57 24 2785 -3 2785 -2 57 -27z
        m-2362 -877 c0 -36 -70 -155 -123 -209 -99 -101 -222 -152 -368 -152 -151 0
        -281 57 -379 167 -51 57 -110 160 -110 192 l0 21 490 0 c477 0 490 -1 490 -19z'
							/>
							<path
								strokeLinecap='round'
								strokeLinejoin='round'
								strokeWidth='2'
								d='M3610 6126 l0 -46 -44 0 c-72 0 -72 -46 -1 -52 39 -3 40 -4 43 -46 3
        -38 6 -43 25 -40 18 2 23 11 25 46 l3 42 45 0 c41 0 45 2 42 23 -2 18 -10 23
        -45 25 -42 3 -42 3 -45 45 -2 35 -7 43 -25 45 -21 3 -23 -1 -23 -42z'
							/>
							<path
								strokeLinecap='round'
								strokeLinejoin='round'
								strokeWidth='2'
								d='M4097 4539 c-12 -7 -17 -22 -17 -49 l0 -40 -46 0 c-41 0 -45 -2 -42
        -22 2 -19 10 -24 45 -26 42 -3 42 -3 45 -45 3 -37 6 -42 28 -42 22 0 25 5 28
        42 3 42 3 42 45 45 34 2 42 7 42 23 0 16 -8 21 -42 23 -43 3 -43 4 -43 41 0
        45 -17 64 -43 50z'
							/>
							<path
								strokeLinecap='round'
								strokeLinejoin='round'
								strokeWidth='2'
								d='M5897 4374 c-4 -4 -7 -25 -7 -46 0 -38 0 -38 -39 -38 -46 0 -70 -22
        -51 -45 7 -9 29 -15 51 -15 37 0 38 0 41 -42 2 -35 7 -43 23 -43 16 0 21 8 23
        43 3 42 3 42 43 42 38 0 40 2 37 28 -3 23 -8 27 -40 30 -36 3 -37 5 -40 45 -3
        40 -22 59 -41 41z'
							/>
							<path
								strokeLinecap='round'
								strokeLinejoin='round'
								strokeWidth='2'
								d='M3880 4290 l0 -69 -72 -3 c-70 -3 -73 -4 -73 -28 0 -24 3 -25 73 -28
        l72 -3 0 -69 c0 -68 1 -70 25 -70 24 0 25 2 25 70 l0 70 63 0 c70 0 91 9 85
        36 -3 16 -14 19 -76 22 l-72 3 0 69 c0 68 -1 70 -25 70 -24 0 -25 -2 -25 -70z'
							/>
							<path
								strokeLinecap='round'
								strokeLinejoin='round'
								strokeWidth='2'
								d='M4709 5057 c-99 -66 -50 -217 69 -217 37 0 53 6 75 26 48 45 58 92
        31 149 -29 63 -114 83 -175 42z'
							/>
							<path
								strokeLinecap='round'
								strokeLinejoin='round'
								strokeWidth='2'
								d='M5014 5071 c-30 -13 -62 -58 -70 -96 -19 -102 114 -177 195 -110 84
        72 47 202 -61 211 -24 2 -53 0 -64 -5z'
							/>
							<path
								strokeLinecap='round'
								strokeLinejoin='round'
								strokeWidth='2'
								d='M5289 5063 c-58 -35 -76 -112 -40 -167 26 -39 57 -55 108 -56 35 0
        48 6 78 35 30 30 35 42 35 81 0 54 -23 92 -67 110 -43 18 -82 17 -114 -3z'
							/>
						</g>
					</svg>
				</a>
			</div>

			<label htmlFor='menu-toggle' className='pointer-cursor md:hidden block'>
				<svg className='fill-current text-gray-900' xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20'>
					<title>menu</title>
					<path d='M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z'></path>
				</svg>
			</label>
			<input className='hidden' type='checkbox' id='menu-toggle' />

			<div className='hidden md:flex md:items-center md:w-auto w-full space-y-1' id='menu'>
				<nav>
					<ul className='md:flex items-center justify-between text-base text-gray-700 pt-4 md:pt-0'>
						{user && (
							<li>
								<a className='md:p-4 py-2 px-0 block' href='/notifications'>
									<div className='flex flex-row items-center md:justify-center border-b-2 border-transparent hover:border-primary-900 '>
										<svg className='w-4 h-4' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
											<path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9' />
										</svg>
										<span className='ml-1 relative'>
											Notifications
											<span className='absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-textcolor-200 transform translate-x-1/2 -translate-y-1/2 bg-primary-900 rounded-full'>
												<NotificationsCount uid={user.uid} />
											</span>
										</span>
									</div>
								</a>
							</li>
						)}
						{user && (
							<li>
								<div className='d:p-4 py-2 px-0 block relative group'>
									<div className='flex flex-row items-center md:justify-center border-b-2 border-transparent hover:border-primary-900 '>
										<svg className='w-4 h-4' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
											<path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4' />
										</svg>
										<span className='ml-1'>Settings</span>
									</div>
									<div className='md:absolute md:-left-0 md:top-10 z-10 bg-primary-500 hidden group-hover:block text-sm'>
										<div className='px-2 pt-2 pb-4 bg-primary-500 p-10 w-max'>
											<div className='grid grid-cols-1 gap-4 gap-y-2'>
												<div className='transform hover:scale-110 hover:underline'>
													<a href='/notificationsettings'>Notifications</a>
												</div>
												<div className='transform hover:scale-110 hover:underline'>
													<a href='/apikeys'>API Keys</a>
												</div>
											</div>
										</div>
									</div>
								</div>
							</li>
						)}
						<li>
							<a className='md:p-4 py-2 px-0 block' href='/blog'>
								<div className='flex flex-row items-center md:justify-center border-b-2 border-transparent hover:border-primary-900 '>
									<svg className='w-4 h-4' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
										<path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253'></path>
									</svg>
									<span className='ml-1 relative'>Blog</span>
								</div>
							</a>
						</li>
						<li>
							{user ? (
								<div className='d:p-4 py-2 block relative group md:ml-4'>
									<div className='flex flex-row items-center md:justify-center border-b-2 border-transparent hover:border-primary-900 '>
										{user && user.photoURL ? (
											<img className='rounded-full w-5 h-5 border-2 border-transparent hover:border-primary-900' src={user.photoURL} alt='Andy Leverenz' />
										) : (
											<svg className='w-5 h-5' fill='none' stroke='currentColor' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
												<path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z'></path>
											</svg>
										)}
										<span className='ml-1'>User</span>
									</div>
									<div className='md:absolute md:-left-0 top-10 z-10 bg-grey-300 hidden group-hover:block rounded-md text-sm'>
										<div className='px-2 pt-2 pb-4 bg-primary-500 p-10 w-max'>
											<div className='grid grid-cols-1 gap-4 gap-y-2'>
												<div className='transform hover:scale-110 hover:underline'>
													<a href='/profile'>Profile</a>
												</div>
												<button className='transform hover:scale-110 hover:underline' onClick={() => auth.signOut()}>
													Sign Out
												</button>
											</div>
										</div>
									</div>
								</div>
							) : (
								<a className='md:p-4 py-2 px-0 block' href='/signing'>
									<div className='flex flex-row items-center md:justify-center border-b-2 border-transparent hover:border-primary-900 '>
										<svg className='w-4 h-4' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
											<path strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z' />
										</svg>
										<span className='ml-1'>Sign In</span>
									</div>
								</a>
							)}
						</li>
					</ul>
				</nav>
			</div>
		</header>
	);
};

export default Header;
