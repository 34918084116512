import { useAuthState } from 'react-firebase-hooks/auth';
import { auth, firestore } from '../firebase';
import BlogList from './BlogList';
import HomeLoggedInInfo from './HomeLoggedInInfo';

const Home = () => {
	const [user, loading, error] = useAuthState(auth);
	return (
		<>
			<div className='bg-white bg-opacity-30 rounded-md md:mr-5 md:w-3/4 overflow-y-auto min-h-full md:min-h-0 shadow-sm'>
				<div className='p-5'>
					{user && <HomeLoggedInInfo />}
					<h2 className='text-3xl'>My Crypto Orders</h2>
					<p className='pt-2'>MyCryptoOrders.com helps with the notification of Crypto exchanges orders from Coinbase Pro API. Especially for limit orders.</p>
					<h2 className='text-3xl mt-5'>How it works?</h2>
					<ol className='list-decimal ml-7 mt-3'>
						<li>
							<a href='/signing' className='font-semibold hover:underline'>
								SignUp or SignIn
							</a>{' '}
							with Google or Email.
						</li>
						<li>
							Add{' '}
							<a href='/apikeys' className='font-semibold hover:underline'>
								API Keys
							</a>{' '}
							of exchange (currently supports Coinbase Pro. More to come...)
						</li>
						<li>
							Go to{' '}
							<a href='/notificationsettings' className='font-semibold hover:underline'>
								notification preferences
							</a>{' '}
							and select the ways you want to receive notifications.
						</li>
						<li>
							In about 15 minutes, you will see your{' '}
							<a href='/orders' className='font-semibold hover:underline'>
								orders
							</a>{' '}
							in orders page.
						</li>
						<li>When your order completed, you will get notified and make that money work right away.</li>
					</ol>{' '}
				</div>
			</div>
			<div className='mt-5 md:mt-0 md:w-1/4 shadow-sm'>
				<BlogList />
			</div>
		</>
	);
};

export default Home;
