import Markup from 'interweave';
import { withRouter } from 'react-router';
import { useDocumentOnce } from 'react-firebase-hooks/firestore';
import { firestore } from '../firebase';
import { decompress } from '../helpers/commonFunctions';

const ViewPost = (props) => {
	const { postid } = props.match.params;
	const [value, loading, error] = useDocumentOnce(postid && firestore.doc('data/blog/content/' + postid), {
		snapshotListenOptions: { includeMetadataChanges: true },
	});
	if (!postid) {
		return (
			<p>
				No PostId. Click <a href='/'>here</a> for home.
			</p>
		);
	} else {
		if (loading) {
			return <p>Loading...</p>;
		} else if (error || value.data() == undefined) {
			return (
				<p>
					Some error occured. Click{' '}
					<a className='font-semibold underline' href='/'>
						here
					</a>{' '}
					for home.
				</p>
			);
		} else {
			const doc = value.data();
			return (
				<div className='bg-white bg-opacity-30 rounded-md md:mr-5 overflow-y-auto min-h-full md:min-h-0 shadow-sm p-5'>
					<div className='text-2xl'>{doc.t}</div>
					<div>
						<Markup content={decompress(doc.cHTML)} />
					</div>
				</div>
			);
		}
	}
};

export default ViewPost;
