import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

let config = {
	apiKey: 'AIzaSyAXX-F2ihiW33X0oqcdeN6e5Rmp-HpDVGo',
	authDomain: 'mycryptoorders.firebaseapp.com',
	projectId: 'mycryptoorders',
	storageBucket: 'mycryptoorders.appspot.com',
	messagingSenderId: '47282507853',
	appId: '1:47282507853:web:af56a93c97ffc1ca228c04',
};

if (!firebase.apps.length) firebase.initializeApp(config);

const auth = firebase.auth();
const firestore = firebase.firestore();
const projectStorage = firebase.storage();
const timestamp = firebase.firestore.FieldValue.serverTimestamp;

export { auth, firestore, projectStorage, timestamp };
