import React from 'react';
import { useCollection } from 'react-firebase-hooks/firestore';
import { useAuthState } from 'react-firebase-hooks/auth';
import { firestore, auth } from '../firebase';
import NotificationItem from './NotificationItem';
import BlogList from './BlogList';

const Notifications = (props) => {
	const [user, userloading, usererror] = useAuthState(auth);
	const [value, loading, error] = useCollection(
		user &&
			firestore
				.collection('/users/' + user.uid + '/notifications')
				.where('status', '!=', 'read')
				.orderBy('status'),
		{
			snapshotListenOptions: { includeMetadataChanges: true },
		}
	);
	const markNotificationRead = async (notificationID) => {
		if (user) {
			/* Mark notification as read */
			await firestore
				.doc(`users/${user.uid}/notifications/${notificationID}`)
				.set({ status: 'read' }, { merge: true })
				.then((e) => {
					// if (list.id === selectedList.id) {
					//   handleListItemClick(todoLists[0], 0);
					// }
				});
			/* Update notifications count */
			var notifications_unread = 0;
			await firestore
				.doc(`users/${user.uid}`)
				.get()
				.then((doc) => {
					notifications_unread = doc.data().notifications_unread;
				});
			if (notifications_unread > 0) {
				await firestore.doc(`users/${user.uid}`).set({ notifications_unread: notifications_unread - 1 }, { merge: true });
			}
		}
	};

	if (!user) {
		props.history.push('/');
	}
	if (userloading) {
		return <p>Loading...</p>;
	} else if (user) {
		if (loading) {
			return <p>Loading...</p>;
		} else if (error) {
			return (
				<p>
					Some error occured. Click{' '}
					<a className='font-semibold underline' href='/'>
						here
					</a>{' '}
					for home.
				</p>
			);
		} else {
			return (
				<>
					<div className='bg-white bg-opacity-30 rounded-md md:mr-5 md:w-3/4 overflow-y-auto min-h-full md:min-h-0 shadow-sm'>
						<div className='bg-white bg-opacity-30 p-5 rounded-md h-full'>
							<h2 className='text-2xl mb-5'>Notifications</h2>
							{value && (
								<>
									{value.docs.map((doc) => {
										const notification = { id: doc.id, ...doc.data() };
										return (
											<React.Fragment key={notification.id}>
												<NotificationItem notification={notification} markNotificationRead={markNotificationRead} />
											</React.Fragment>
										);
									})}
								</>
							)}
							<p className='my-2 text-md'>
								Take me to{' '}
								<a className='font-semibold underline transform hover:font-bold hover:bg-yellow-200' href='/orders'>
									Orders
								</a>
								.
							</p>
						</div>
					</div>
					<div className='mt-5 md:mt-0 md:w-1/4 shadow-sm'>
						<BlogList />
					</div>
				</>
			);
		}
	}
};

export default Notifications;
