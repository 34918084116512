import firebase from 'firebase/app';
import { auth, firestore } from '../firebase';
import BlogList from './BlogList';
import { useState } from 'react';

const Signing = (props) => {
	const [name, setName] = useState('');
	const [email, setEmail] = useState('');
	const [passwd, setPasswd] = useState('');
	const [isSignIn, setIsSignIn] = useState(true);

	/* Google SignIn */
	const signInWithGoogle = () => {
		const provider = new firebase.auth.GoogleAuthProvider();
		auth.signInWithPopup(provider);
	};

	/* Create default user document after signup/signin */
	const createUserDoc = async (userid) => {
		const userRef = firestore.doc(`users/${userid}`);
		const snapshot = await userRef.get();

		if (!snapshot.exists) {
			const { email, displayName, photoURL } = auth.currentUser;
			try {
				await userRef.set(
					{
						displayName,
						email,
						photoURL,
						createdAt: Math.round(new Date().getSeconds() / 1000),
						notifications_unread: 0,
						open_orders: 0,
						latest_order_refresh_time: 12345,
						quote: 'Welcome',
					},
					{ merge: true }
				);
			} catch (error) {
				console.error('Error creating user document', error);
			}
		}
	};

	/* SignIn with email and password */
	const signInWithEmail = async (e) => {
		e.preventDefault();
		var userSignedIn;
		await auth
			.signInWithEmailAndPassword(email, passwd)
			.then((user) => {
				console.log('SignIn with Email successful.', user);
				userSignedIn = user;
			})
			.catch((err) => console.log('SignIn with Email error: ', err));
		if (userSignedIn) {
			await createUserDoc(userSignedIn.user.uid);
			props.history.push('/');
		}
	};

	/* SignUp with email and password */
	const signUpWithEmail = async (e) => {
		e.preventDefault();
		var userSignedIn;
		auth
			.createUserWithEmailAndPassword(email, passwd)
			.then((user) => {
				userSignedIn = user;
			})
			.catch((err) => console.log('SignUp with Email error: ', err));
		if (userSignedIn) {
			await createUserDoc(userSignedIn.user.uid);
			props.history.push('/');
		}
	};

	return (
		<>
			<div className='bg-white bg-opacity-30 rounded-md md:mr-5 md:w-3/4 overflow-y-auto min-h-full md:min-h-0 shadow-sm'>
				<div className='w-full p-6 md:px-32'>
					<div className='w-full text-center'>
						<a href='#' className='bg-blue-300 hover:bg-primary-400 text-textcolor-700 justify-center font-bold py-2 px-4 mb-10 text-center inline-block' onClick={signInWithGoogle}>
							<svg className='w-4 h-4 inline-block align-middle' viewBox='0 0 256 262' xmlns='http://www.w3.org/2000/svg'>
								<path d='M255.878 133.451c0-10.734-.871-18.567-2.756-26.69H130.55v48.448h71.947c-1.45 12.04-9.283 30.172-26.69 42.356l-.244 1.622 38.755 30.023 2.685.268c24.659-22.774 38.875-56.282 38.875-96.027' fill='#4285F4' />
								<path d='M130.55 261.1c35.248 0 64.839-11.605 86.453-31.622l-41.196-31.913c-11.024 7.688-25.82 13.055-45.257 13.055-34.523 0-63.824-22.773-74.269-54.25l-1.531.13-40.298 31.187-.527 1.465C35.393 231.798 79.49 261.1 130.55 261.1' fill='#34A853' />
								<path d='M56.281 156.37c-2.756-8.123-4.351-16.827-4.351-25.82 0-8.994 1.595-17.697 4.206-25.82l-.073-1.73L15.26 71.312l-1.335.635C5.077 89.644 0 109.517 0 130.55s5.077 40.905 13.925 58.602l42.356-32.782' fill='#FBBC05' />
								<path d='M130.55 50.479c24.514 0 41.05 10.589 50.479 19.438l36.844-35.974C195.245 12.91 165.798 0 130.55 0 79.49 0 35.393 29.301 13.925 71.947l42.211 32.783c10.59-31.477 39.891-54.251 74.414-54.251' fill='#EB4335' />
							</svg>
							<span className='pl-3 inline-block align-middle'>Google</span>
						</a>
					</div>
					<hr className='border-white mb-3' />
					{/* Tabs */}
					<div className='flex flex-row items-center text-center mb-5'>
						<div className={isSignIn ? 'w-1/2 py-4 ml-2 border-b-4 border-primary-900' : 'w-1/2 py-4 ml-2 border-b-2'}>
							<button
								onClick={() => {
									setIsSignIn(true);
								}}
							>
								Sign in
							</button>
						</div>
						<div className={isSignIn ? 'w-1/2 py-4 ml-2 border-b-2' : 'w-1/2 py-4 ml-2 border-b-4 border-primary-900'}>
							<button
								onClick={() => {
									setIsSignIn(false);
								}}
							>
								Register
							</button>
						</div>
					</div>
					<form className={isSignIn ? 'mx-auto' : 'hidden'}>
						<p className='text-xl mb-5'>Sign in with e-mail and Password</p>
						<div className='flex items-center bg-white bg-opacity-70 rounded-t-lg border-b border-white'>
							<label htmlFor='sEmail' className='w-20 text-right mr-8 text-gray-700'>
								Email
							</label>
							<input type='text' id='sEmail' name='sEmail' placeholder='yourid@gmail.com' className='flex-1 p-4 pl-0 bg-transparent placeholder-gray-500 outline-none text-gray-700' value={email} onChange={(e) => setEmail(e.target.value)} />
						</div>
						<div className='flex items-center bg-white bg-opacity-70 rounded-b-lg border-b border-white'>
							<label htmlFor='sPasswd' className='w-20 text-right mr-8 text-gray-700'>
								Password
							</label>
							<input type='password' id='sPasswd' name='sPasswd' placeholder='password' className='flex-1 p-4 pl-0 bg-transparent placeholder-gray-500 outline-none text-gray-700' value={passwd} onChange={(e) => setPasswd(e.target.value)} />
						</div>
						<div className='w-full text-center'>
							<button className='px-6 mt-5 rounded bg-primary-700 hover:shadow-lg hover:bg-primary-500 py-3 text-white font-bold shadow' onClick={signInWithEmail}>
								Sign In
							</button>
						</div>
					</form>
					<form className={isSignIn ? 'hidden' : 'mx-auto'}>
						<p className='text-xl mb-5'>Register</p>
						<div className='flex items-center bg-white bg-opacity-70 rounded-t-lg border-b border-white'>
							<label htmlFor='name' className='w-20 text-right mr-8 text-gray-700'>
								Name
							</label>
							<input type='text' id='name' name='name' placeholder='Full Name' className='flex-1 p-4 pl-0 bg-transparent placeholder-gray-500 outline-none text-gray-700' value={name} onChange={(e) => setName(e.target.value)} />
						</div>
						<div className='flex items-center bg-white bg-opacity-70 border-b border-white'>
							<label htmlFor='email' className='w-20 text-right mr-8 text-gray-700'>
								Email
							</label>
							<input type='text' id='email' name='email' placeholder='yourid@gmail.com' className='flex-1 p-4 pl-0 bg-transparent placeholder-gray-500 outline-none text-gray-700' value={email} onChange={(e) => setEmail(e.target.value)} />
						</div>
						<div className='flex items-center bg-white bg-opacity-70 rounded-b-lg border-b border-white'>
							<label htmlFor='name' className='w-20 text-right mr-8 text-gray-700'>
								Password
							</label>
							<input type='password' id='passwd' name='passwd' placeholder='password' className='flex-1 p-4 pl-0 bg-transparent placeholder-gray-500 outline-none text-gray-700' value={passwd} onChange={(e) => setPasswd(e.target.value)} />
						</div>
						<div className='text-center mb-5'>
							<button type='submit' className='px-6 mt-5 mb-10 rounded bg-primary-700 hover:shadow-lg hover:bg-primary-500 py-3 text-white font-bold shadow' onClick={signUpWithEmail}>
								Create Account
							</button>
						</div>
					</form>
				</div>
			</div>
			<div className='mt-5 md:mt-0 md:w-1/4 shadow-sm'>
				<BlogList />
			</div>
		</>
	);
};

export default Signing;
