import { currencyFormat } from '../helpers/commonFunctions';

const NotificationItem = (props) => {
	return (
		<div className={'border-2 border-white border-opacity-70 h-35 p-3 rounded-md mb-3 flex flex-row items-center ' + (props.notification.side.toLowerCase() === 'buy' ? 'bg-red-300' : 'bg-green-300') + ' bg-opacity-40'}>
			<div className='flex-grow pr-2'>
				<span className='text-md md:text-xl'>{props.notification.product_id}</span>'s <span className='text-md md:text-xl'>{props.notification.side}</span> order for <span className='text-md md:text-xl'>{currencyFormat(props.notification.executed_value)}</span> is completed in {props.notification.api_name}.
			</div>
			<button
				className='flex-none bg-primary-300 hover:bg-primary-400 text-textcolor-800 font-bold py-2 px-2 rounded inline-flex items-center float-right h-10'
				onClick={(e) => {
					e.preventDefault();
					props.markNotificationRead(props.notification.id);
				}}
			>
				<svg className='fill-current w-4 h-4 mr-2' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
					<path strokeLinecap='round' strokeLinejoin='round' strokeWidth={2} d='M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253' />
				</svg>
				<span>Clear</span>
			</button>
		</div>
	);
};

export default NotificationItem;
