import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/Home';
import { Switch, Route } from 'react-router-dom';
import { auth } from './firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import Signing from './components/Signing';
import ViewPost from './components/ViewPost';
import Notifications from './components/Notifications';
import Orders from './components/Orders';
import ApiKeys from './components/ApiKeys';
import NotificationPreferences from './components/NotificationPreferences';
import BlogCreatePost from './components/BlogCreatePost';
import BlogList from './components/BlogList';
import Profile from './components/Profile';
import AboutUs from './components/AboutUs';

function App() {
	const [user, loading] = useAuthState(auth);
	if (loading) {
		return <div className='h-screen bg-primary-300 flex items-center justify-center'>Initializing...</div>;
	}
	return (
		<div className='h-screen bg-primary-300 flex flex-col'>
			<Header />
			<div className='flex-1 overflow-y-auto p-5 place-self-center w-screen lg:w-11/12 lg:px-20 flex flex-col sm:flex-row'>
				<Switch>
					<Route exact path='/' component={Home} />
					<Route exact path='/about' component={AboutUs} />
					<Route path='/signing' component={Signing} />
					<Route path='/notifications' component={Notifications} />
					<Route path='/orders' component={Orders} />
					<Route path='/apikeys' component={ApiKeys} />
					<Route path='/createBlogPost' exact component={BlogCreatePost} />
					<Route path='/createBlogPost/:postid' component={BlogCreatePost} />
					<Route path='/blog' exact component={BlogList} />
					<Route path='/blog/:postid' component={ViewPost} />
					<Route path='/notificationsettings' component={NotificationPreferences} />
					<Route path='/profile' exact component={Profile} />
				</Switch>
			</div>

			<Footer />
		</div>
	);
}

export default App;
